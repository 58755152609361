<template>
    <!--begin::Dashboard-->
    <div class="card card-custom gutter-b col-lg-13 col-xxl-12 mt-5 p-5">
        <b-skeleton-wrapper :loading="loading">
            <template #loading>
                <div class="d-flex align-items-center flex-row justify-content-between mb-5 pb-5 border-secondary border-bottom">
                    <b-skeleton type="button" width="12%"></b-skeleton>
                    <b-skeleton type="input" width="40%"></b-skeleton>
                    <span></span>
                </div>
                <div class=" mt-5 d-flex flex-column justify-content-around">
                    <div class=" mt-5 d-flex flew-row justify-content-between align-items-center">
                        <div class="mt-5" style="width:70%">
                            <b-skeleton width="85%"></b-skeleton>
                            <b-skeleton type="input" width="85%"></b-skeleton>
                        </div>
                        <div class="mr-10 mt-5" style="width:30%">
                            <b-skeleton width="95%"></b-skeleton>
                            <b-skeleton type="input" width="95%"></b-skeleton>
                        </div>
                    </div>
                    <div class="mt-10" style="width:100%">
                        <b-skeleton></b-skeleton>
                        <b-skeleton type="input" class="mt-2"></b-skeleton>
                    </div>
                </div>
                <div class="mt-15 d-flex justify-content-center">
                    <b-skeleton class="mr-3" type="button"></b-skeleton>
                    <b-skeleton type="button"></b-skeleton>
                </div>
            </template>
            <div class="d-flex align-items-center flex-row justify-content-between mb-5 pb-5 border-secondary border-bottom">
                <router-link :to="{ name: 'classes/edit', params: { id: this.$route.params.id } }" replace>
                    <span class="btn btn-outline-danger btn-md">
                        <span class="svg-icon svg-icon-md svg-icon-light-danger">
                            <inline-svg src="/media/svg/icons/Navigation/Arrow-left.svg"></inline-svg>
                        </span>
                        {{ $t('CONTROLLERS.BACK') }}
                    </span>
                </router-link>
                <h2 class="h2 mt-5">
                    {{ $t('LESSON.FORMTITLE', { title: this.$route.params.name }) }}
                </h2>
                <span></span>
            </div>
            <!--begin::Form-->
            <b-form class="my-5 px-5" @submit="onSubmit" @reset="onReset" v-if="show">
                <div class="d-flex flex-column justify-content-around">
                    <div class="d-flex flew-row justify-content-between align-items-center">
                        <b-form-group class="col-5" id="input-group-title" style="width : 40%" :label="$t('LESSON.TITLE.LABEL')"
                                      label-for="input-title">
                            <b-form-input
                                id="input-title"
                                v-model="lesson.title"
                                type="text"
                                required
                                :placeholder="$t('LESSON.TITLE.INPUT')"
                            >
                            </b-form-input>
                        </b-form-group>

                        <div class="col" style="width:25%; text-align: left">
                            <b-form-checkbox name="linear" v-model="lesson.linear" id="linear" switch>
                                {{ $t('LESSON.LINEAR.FALSE.LABEL') }}
                            </b-form-checkbox>
                            <p class="text-muted text-left">
                                {{ $t('LESSON.LINEAR.FALSE.INFO') }}
                            </p>
                        </div>
                        <span class="col"></span>
                    </div>
                    <div class="d-flex flew-row justify-content-between align-items-center">
                        <div class="col-3" style="width:25%; text-align: left">
                            <div class="d-flex align-items-center justify-content-center">
                            <span v-if="lesson.media" style="cursor: pointer" class="svg-icon svg-icon-md mr-4"
                                  @click="downloadSummary(lesson.media)">
                                <inline-svg src="/media/svg/files/pdf.svg"></inline-svg>
                            </span>
                                <span v-else class="svg-icon svg-icon-md mr-4">
                                <inline-svg src="/media/svg/files/pdf.svg"></inline-svg>
                            </span>
                                <div v-if="lesson.media_id || lesson.synthese" style="cursor: pointer" @click="downloadSummary(lesson.media)">
                                    <p class="m-0">{{ media_name }}</p>
                                </div>
                                <div v-else style="width: 100%;">
                                    <p class="m-0">Pas de fiche synthèse ajoutée</p>
                                </div>
                            </div>
                            <b-form-group id="input-group-synthese"
                                          label-for="input-title">
                                <label v-if="!lesson.media_id && !lesson.synthese"
                                       class="mt-4 btn btn-primary btn-md btn-block"
                                       for="synthese">Ajouter
                                    une fiche synthèse (.pdf)</label>
                                <label v-else class="mt-4 btn btn-primary btn-md btn-block" for="synthese">Modifier la
                                    fiche synthèse (.pdf)</label>
                                <input type="file" ref="lesson.synthese" id="synthese" name="synthese"
                                       accept="application/pdf"
                                       style="display:none" @change="handleFileUpload"/>
                            </b-form-group>
                        </div>
                        <span class="col"></span>
                        <div class="col-5" style="width:35%; text-align: left" v-if="this.$route.params.classroom === 1">
                            <div class="d-flex align-items-center">
                                <label for="min_duration">{{ $t('LESSON.MIN_DURATION.LABEL') }}</label>
                                <b-form-input name="min_duration" type="number" v-model="lesson.min_duration"
                                              id="min_duration" class="w-25 ml-5" min="1"></b-form-input>
                            </div>
                            <p class="text-muted text-left mt-2">
                                {{ $t('LESSON.MIN_DURATION.INFO') }}
                            </p>
                        </div>
                        <span class="col"></span>
                    </div>
                    <b-form-group id="input-group-description" :label="$t('LESSON.DESCRIPTION.LABEL')" label-for="input-description">
                        <b-form-textarea
                            id="input-description"
                            v-model="lesson.description"
                            type="text"
                            rows="4"
                            max-rows="6"
                            required
                            :placeholder="$t('LESSON.DESCRIPTION.INPUT')"
                        >
                        </b-form-textarea>
                    </b-form-group>
                </div>
                <div class="mt-5 text-center">
                    <b-button type="submit" class="mx-5 btn-lg" variant="primary">
                        {{ $t('CONTROLLERS.ADD') }}
                    </b-button>
                    <b-button type="reset" variant="danger">{{ $t('CONTROLLERS.ERASE') }}</b-button>
                </div>
            </b-form>
            <!--end::Form-->
        </b-skeleton-wrapper>
        <div
            v-if="submit"
            style="position:absolute;top:0;left:0;width:100%;height:100%;z-index:100;background-color: #ffffff9e;backdrop-filter: blur(5px)"
            class="d-flex flex-column justify-content-around align-items-center"
        >
            <div style="z-index:200;" class="d-flex flex-column justify-content-around align-items-center">
                <b-spinner style="width: 3rem; height: 3rem;" variant="primary" />
                <p class="text-primary py-2 my-2">Enregistrement en cours ...</p>
            </div>
        </div>
    </div>
    <!--end::Dashboard-->
</template>

<script>
import {SET_BREADCRUMB} from '@/core/services/store/breadcrumbs.module';
import ApiService from '@/core/services/api.service';
import i18n from '@/core/plugins/vue-i18n.js';

export default {
    name: 'admin-add-lesson',
    components: {},
    mounted() {
        if (!this.$route.params.id) this.$router.push({ name: 'admin/dashboard' });
        this.startLoading();
        this.$store.dispatch(SET_BREADCRUMB, [{ title: i18n.t('MENU.DASHBOARD') }]);
    },
    data() {
        return {
            loading: false,
            loadingTime: 0,
            maxLoadingTime: 1,
            show: true,
            submit: false,
            lesson: {
                title: '',
                description: '',
                linear: true,
                synthese: null,
                min_duration: null
            },
            media_name: null,
        };
    },
    methods: {
        onReset(evt) {
            evt.preventDefault();
            this.lesson.title = '';
            this.lesson.description = '';
            this.lesson.linear = true;
            this.lesson.min_duration = null;
            this.show = false;
            this.$nextTick(() => {
                this.show = true;
            });
        },
        handleFileUpload(event) {
            this.lesson.synthese = event.target.files[0];
            this.media_name = event.target.files[0].name;
            this.$forceUpdate();
        },
        downloadSummary(url) {
            window.open(url, 'blank')
        },
        onSubmit(evt) {
            evt.preventDefault();
            if (!this.submit) {
                this.submit = true;
                const formData = new FormData();
                formData.append('classe_id', this.$route.params.id);
                formData.append('title', this.lesson.title);
                formData.append('description', this.lesson.description);
                formData.append('linear', this.lesson.linear === 'true' || this.lesson.linear === true);
                formData.append('synthese', this.lesson.synthese);
                formData.append('min_duration', this.lesson.min_duration);

                ApiService.post(process.env.VUE_APP_API_URL + '/academy/lessons', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(result => {
                    if (result.data) {
                        this.$router.push({
                            name: 'classes/edit',
                            params: {id: result.data.classe_id}
                        });
                    }
                });

            }
        },
        clearLoadingTimeInterval() {
            clearInterval(this.$_loadingTimeInterval);
            this.$_loadingTimeInterval = null;
        },
        startLoading() {
            this.loading = true;
            this.loadingTime = 0;
        }
    },
    watch: {
        loading(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.clearLoadingTimeInterval();

                if (newValue) {
                    this.$_loadingTimeInterval = setInterval(() => {
                        this.loadingTime++;
                    }, 500);
                }
            }
        },
        loadingTime(newValue, oldValue) {
            if (newValue !== oldValue) {
                if (newValue === this.maxLoadingTime) {
                    this.loading = false;
                }
            }
        }
    },
    created() {
        this.$_loadingTimeInterval = null;
    }
};
</script>
